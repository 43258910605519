import { LIMIT, REVALIDATE } from '@/entities/constants';
import {} from '@/shared/api';
import { GetNews200, GetNewsV1Error } from '@/shared/api/codegen/demuzo';
import {
  FetchInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { newsKeys } from '../query-keys';
import { newsApi } from '@/shared/api/config';

export const getNewsInfinityPrefetchConfig = () =>
  ({
    getNextPageParam: (lastPage: GetNews200) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    queryKey: newsKeys.all,
    initialPageParam: 0,
    queryFn: ({ pageParam }: { pageParam: number }) =>
      newsApi.getNews(
        { limit: LIMIT, offset: pageParam * 10 },
        { next: { revalidate: REVALIDATE } },
      ),
    pages: 1,
  }) satisfies FetchInfiniteQueryOptions<
    GetNews200,
    GetNewsV1Error,
    GetNews200,
    typeof newsKeys.all,
    number
  >;

export const useGetNewsInfinity = () => {
  return useInfiniteQuery({
    getNextPageParam: (lastPage: GetNews200) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    queryKey: newsKeys.all,
    initialPageParam: 0,
    queryFn: ({ pageParam }) =>
      newsApi.getNews({ limit: LIMIT, offset: pageParam * 10 }),
  });
};
