import { REVALIDATE } from '@/entities/constants';
import {} from '@/shared/api';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { newsKeys } from '../query-keys';
import { newsApi } from '@/shared/api/config';

type Props = {
  newsId: string;
};

export const getNewsByIdPrefetchConfig = (newsId: string, headers?: any) =>
  ({
    queryKey: [...newsKeys.getNewsById(newsId)],
    queryFn: () =>
      newsApi.getNewsNewsId(
        { newsId },
        { headers, next: { revalidate: REVALIDATE } },
      ),
  }) satisfies FetchQueryOptions;

export const useGetNewsById = ({ newsId }: Props) => {
  return useQuery({
    queryKey: [...newsKeys.getNewsById(newsId)],
    queryFn: () => newsApi.getNewsNewsId({ newsId }),
  });
};
