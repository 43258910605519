import {
  AccordionButton,
  Accordion as AccordionChakra,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Text,
  Box,
} from '@chakra-ui/react';
import IconArrowDropDownFilled from '@ui-kit/icons/icon-arrow-drop-down-filled';
import IconArrowDropUpFilled from '@ui-kit/icons/icon-arrow-drop-up-filled';
import Divider from '@ui-kit/molecules/divider/divider';
import React, { FC } from 'react';
import { AccordionData } from './types';

type Props = {
  data: AccordionData[];
  variant?: 'black' | 'white';
} & AccordionProps;

const Accordion: FC<Props> = ({
  data,
  allowToggle = true,
  variant = 'black',
  ...props
}) => {
  return (
    <AccordionChakra
      allowToggle={allowToggle}
      {...props}
    >
      {data.map((accordionData, index) => (
        <React.Fragment key={accordionData.id}>
          <AccordionItem
            bg={'white'}
            p={0}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  sx={{
                    _hover: { bg: 'none' },
                    _focus: { bg: 'none' },
                    _before: { display: 'none' },
                    _after: { display: 'none' },
                  }}
                  justifyContent='flex-start'
                  p='20px 0'
                >
                  <Text
                    as='span'
                    mr='auto'
                  >
                    {accordionData.title}
                  </Text>

                  <Box flex={1} />

                  {isExpanded ? (
                    <IconArrowDropUpFilled ml='auto' />
                  ) : (
                    <IconArrowDropDownFilled ml='auto' />
                  )}
                </AccordionButton>
                <AccordionPanel p={'5px 0 20px 0'}>
                  {accordionData.text}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {data.length - 1 !== index ? <Divider /> : null}
        </React.Fragment>
      ))}
    </AccordionChakra>
  );
};

export default Accordion;
