import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { CarouselPositionItem } from '../../molecules/carousel-position-item';

type Props = {
  activeIndex: number;
  positionsLength: number;
};

export const CarouselIndicators: FC<Props> = ({
  activeIndex,
  positionsLength,
}) => {
  let currentActiveIndex = activeIndex;
  const isOverLength = positionsLength > 4;
  const isLast = activeIndex === positionsLength - 1;

  if (isOverLength && isLast) {
    currentActiveIndex = 3;
  } else if (isOverLength && activeIndex > 0) {
    currentActiveIndex = activeIndex < positionsLength / 2 ? 1 : 2;
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      gap='10px'
      p='20px 0'
    >
      {positionsLength >= 2 ? (
        <CarouselPositionItem isActive={currentActiveIndex === 0} />
      ) : null}
      {positionsLength >= 2 ? (
        <CarouselPositionItem isActive={currentActiveIndex === 1} />
      ) : null}
      {positionsLength >= 3 ? (
        <CarouselPositionItem isActive={currentActiveIndex === 2} />
      ) : null}
      {positionsLength >= 4 ? (
        <CarouselPositionItem isActive={currentActiveIndex === 3} />
      ) : null}
    </Box>
  );
};
