import { CarouselIndicators } from '@/shared/ui-kit/organisms/carousel-indicators';
import { Box } from '@chakra-ui/react';
import HomeHowItWorksItem from '@ui-kit/molecules/home-how-it-works-item/home-how-it-works-item';
import { HowItWorkItem } from '@ui-kit/molecules/home-how-it-works-item/types';
import { useState } from 'react';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import './styles.css';
import { Image } from '@chakra-ui/next-js';
import { useTranslations } from 'next-intl';

const swiperModules = [FreeMode];

export const HomeHowItWorks = () => {
  const t = useTranslations();
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useIsMobile();

  const onSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <Box
      px={{ base: '20px', lg: 0 }}
      mt={{ base: '20px', lg: 0 }}
      display={{ base: undefined, lg: 'flex' }}
      justifyContent={{ base: undefined, lg: 'flex-end' }}
    >
      <Swiper
        spaceBetween={20}
        slidesPerView={isMobile ? 1 : 'auto'}
        freeMode={!isMobile}
        onSlideChange={onSlideChange}
        modules={swiperModules}
        className={'how-it-works-swiper'}
      >
        <SwiperSlide
          virtualIndex={0}
          className={'how-it-works-slide'}
        >
          <HomeHowItWorksItem
            image={
              <Image
                src='/images/image-how-it-works-1.webp'
                alt={t('HomeHowItWorks.Item1.imageAlt')}
                width={249}
                height={170}
                style={{ objectFit: 'cover' }}
              />
            }
            text={t('HomeHowItWorks.Item1.text')}
            title={t('HomeHowItWorks.Item1.title')}
            count={t('HomeHowItWorks.Item1.count')}
          />
        </SwiperSlide>
        <SwiperSlide
          virtualIndex={1}
          className={'how-it-works-slide'}
        >
          <HomeHowItWorksItem
            image={
              <Image
                src='/images/image-how-it-works-2.webp'
                alt={t('HomeHowItWorks.Item2.imageAlt')}
                width={249}
                height={170}
                style={{ objectFit: 'cover' }}
              />
            }
            text={t('HomeHowItWorks.Item2.text')}
            title={t('HomeHowItWorks.Item2.title')}
            count={t('HomeHowItWorks.Item2.count')}
          />
        </SwiperSlide>
        <SwiperSlide
          virtualIndex={2}
          className={'how-it-works-slide'}
        >
          <HomeHowItWorksItem
            image={
              <Image
                src='/images/image-how-it-works-3.webp'
                alt={t('HomeHowItWorks.Item3.imageAlt')}
                width={249}
                height={170}
                style={{ objectFit: 'cover' }}
              />
            }
            text={t('HomeHowItWorks.Item3.text')}
            title={t('HomeHowItWorks.Item3.title')}
            count={t('HomeHowItWorks.Item3.count')}
          />
        </SwiperSlide>
        <SwiperSlide
          virtualIndex={3}
          className={'how-it-works-slide'}
        >
          <HomeHowItWorksItem
            image={
              <Image
                src='/images/image-how-it-works-4.webp'
                alt={t('HomeHowItWorks.Item4.imageAlt')}
                width={249}
                height={170}
                style={{ objectFit: 'cover' }}
              />
            }
            text={t('HomeHowItWorks.Item4.text')}
            title={t('HomeHowItWorks.Item4.title')}
            count={t('HomeHowItWorks.Item4.count')}
          />
        </SwiperSlide>
      </Swiper>

      <Box display={{ base: 'block', lg: 'none' }}>
        <CarouselIndicators
          activeIndex={activeIndex}
          positionsLength={4}
        />
      </Box>
    </Box>
  );
};

export default HomeHowItWorks;
