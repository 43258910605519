import { AccordionData } from '@ui-kit/atoms/accordion/types';

type TAccordionsData = {
  title: string;
  values: AccordionData[];
};

export const accordionsData: TAccordionsData[] = [
  {
    title: 'General Information',
    values: [
      {
        id: '1',
        title: 'What is Demuzo?',
        text: 'Demuzo is an innovative platform that allows you to support artists and producers by acquiring shares in the future earnings of their songs. It offers a distinctive opportunity to participate in music projects, where you can share in the revenue generated by a track’s success.',
      },
      {
        id: '2',
        title: 'What does Demuzo mean?',
        text: 'The name Demuzo comes from "DE" (from the Greek "Demos" — people) and "MUZO" (from "muse" — inspiration). The project embodies the idea of democratizing participation in music projects. So far, only major players in the music industry have had access to earning a share of music revenues.. Demuzo is changing the game by giving everyone the chance to support artists and get involved in music projects. The platform breaks down barriers to the music industry, letting anyone share in the success of artists without needing to be part of the traditional system.',
      },
      {
        id: '3',
        title: 'How does the platform work?',
        text: 'Demuzo organizes campaigns for the sale of shares in future earnings (Royalty Shares) of music releases. In each campaign, artists or labels offer you the chance to purchase a share of a track’s earnings. The funds raised typically go towards promotion (e.g., social media ads) and studio recording. We provide full transparency regarding how the funds will be used, so you can see exactly how your contribution supports the project.',
      },
      {
        id: '4',
        title: 'Who are you guys?',
        text: 'We are a team of professionals in the music, IT, finance, legal, and content management industries. We’ve combined our knowledge and experience to create a platform that helps labels and artists to release successful music tracks. We believe everyone should have the chance to support the artists who inspire them and receive a share of the revenue if the project succeeds.',
      },
    ],
  },
  {
    title: 'Purchasing and Withdrawal',
    values: [
      {
        id: '1',
        title: 'How can I purchase Royalty Shares?',
        text: 'To purchase Royalty Shares, you need to create an account with Demuzo, top up your balance, choose a campaign, and select the number of shares you want to buy. Once you make the payment, you’ll receive your Royalty Shares when the campaign track raises the required funding.',
      },
      {
        id: '2',
        title: 'What happens if the funding campaign fails?',
        text: 'If the campaign doesn’t reach its target, all collected funds will be returned to each user’s balance on the platform according to their contributions. You may either withdraw the funds or use them for other campaigns on Demuzo. We ensure transparency and security for your contributions.',
      },
      {
        id: '3',
        title: 'How will I receive earnings?',
        text: 'Earnings from your shares will be credited to your platform balance. Payouts occur periodically, but the frequency depends on the terms of the release and its performance.',
      },
      {
        id: '4',
        title:
          'What are the benefits of participating in music projects through Royalty Shares?',
        text: 'You become part of the music process, supporting artists and sharing in the revenue. It’s also an opportunity to build a diversified music portfolio.',
      },
      {
        id: '5',
        title: 'How are track revenues distributed?',
        text: 'Platforms like Spotify and Apple Music pay royalties to rights holders, who then distribute a portion of these earnings to the owners of shares via Demuzo platform.',
      },
      {
        id: '6',
        title: 'Deposits and Withdrawals',
        text: 'Funds can be deposited and withdrawn via PayPal. Withdrawal processing typically takes 1 to 7 days.',
      },
      {
        id: '7',
        title: 'What is the minimum withdrawal amount?',
        text: 'The minimum withdrawal amount from your balance is $50.',
      },
      {
        id: '8',
        title: 'Do you charge fees? How do you earn revenue?',
        text: 'We charge a 10% fee on each balance deposit to cover payment processing fees and platform maintenance costs. Our primary revenue comes from participating in projects alongside our users.',
      },
    ],
  },
  {
    title: 'Legal and Security',
    values: [
      {
        id: '1',
        title: 'Does Demuzo guarantee a return on investment?',
        text: 'No, the platform offers no guarantees. The success of each project depends on various factors, and there’s no way to ensure a track will become popular or generate revenue. You should be prepared for the possibility of not recovering the money you put in.',
      },
      {
        id: '2',
        title: 'Is this legal? Under which country’s laws do you operate?',
        text: 'Yes, the platform operates under U.S. law, specifically in the state of Wyoming. Once a Royalty Share sale campaign is completed, you can request a certificate and documents confirming your share ownership.',
      },
      {
        id: '3',
        title: 'What happens to my Royalty Shares if Demuzo shuts down?',
        text: 'In the very unlikely event that the platform shuts down, the distributor will handle the distribution based on the documents you receive with your certificate.',
      },
      {
        id: '4',
        title: 'Why do you require KYC?',
        text: 'The KYC process is necessary to comply with legal regulations and prevent fraud.',
      },
      {
        id: '5',
        title: 'Is participating in music projects risky?',
        text: 'Yes, there are risks involved in participating in music projects. Even though we select releases with strong potential, success is not guaranteed. Be sure to carefully consider these risks and make informed decisions.',
      },
    ],
  },
  {
    title: 'For Artists, Labels, and Producers',
    values: [
      {
        id: '1',
        title: 'How can I use Demuzo as an artist?',
        text: 'Create an account and contact our team. After your profile is verified and approved, you’ll be able to launch Royalty Share campaigns.',
      },
      {
        id: '2',
        title: 'Can any artist launch a Royalty Share sale?',
        text: 'No, we only work with artists and labels who have more than 20,000 monthly listeners on Spotify.',
      },
      {
        id: '3',
        title: 'Do I need to be signed to a label? Do I need a manager?',
        text: 'It depends. We typically work with labels, but if you’ve proven you can achieve significant results independently, we’re open to discuss collaboration.',
      },
      {
        id: '4',
        title: 'What if I don’t own all the rights to my music?',
        text: 'This depends on your situation. We might be able to work with your label or distributor. Reach out to us to discuss options.',
      },
      {
        id: '5',
        title: 'Which genres are accepted?',
        text: 'We are open to all genres. Our decisions are based on data analysis, expert opinions, and algorithms that predict a release’s financial potential.',
      },
      {
        id: '6',
        title: 'Why might my release not be accepted on the platform?',
        text: 'We don’t disclose all selection criteria, but your chances may be lower if you have fewer than 40,000 monthly Spotify streams or less than $500 in monthly distributor revenue, or if the expert forecast is negative.',
      },
    ],
  },
];
