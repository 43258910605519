export const newsKeys = {
  all: ['news'] as const,
  getNews: ({ limit, offset }: { limit: number; offset: number }) => [
    ...newsKeys.all,
    'getNews',
    offset,
    limit,
  ],
  getUserNews: (userId: string) => [...newsKeys.all, 'userNews', userId],
  getProductNews: (newsId: string) => [
    ...newsKeys.all,
    'getProductNews',
    newsId,
  ],
  getNewsById: (newsId: string) => [...newsKeys.all, 'getNewsById', newsId],
} as const;
