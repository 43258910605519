import { Box, Flex, Text } from '@chakra-ui/react';
import { HowItWorkItem } from './types';

type Props = HowItWorkItem;

export const HomeHowItWorksItem = ({ count, image, text, title }: Props) => {
  return (
    <Box w='full'>
      <Flex justify='space-between'>
        <Text textStyle='one-line-text-regular-mono-sm'>{count}</Text>

        {image}
      </Flex>

      <Flex
        direction='column'
        mt='-20px'
        mb='20px'
      >
        <Text
          as='h3'
          textStyle='heading-small-2xl'
        >
          {title}
        </Text>

        <Text
          textStyle='text-regular-sm'
          mt='20px'
          w='249px'
          alignSelf='flex-end'
        >
          {text}
        </Text>
      </Flex>
    </Box>
  );
};

export default HomeHowItWorksItem;
