import { Box, Flex, Text } from '@chakra-ui/react';
import Button from '@ui-kit/atoms/button/button';
import { useTranslations } from 'next-intl';

export const HomePartners = () => {
  const t = useTranslations();

  return (
    <Box m={{ base: '20px auto 0', lg: '100px auto 0' }}>
      <Flex
        direction='column'
        justify='space-between'
        minH={{ base: '482px', lg: '783px' }}
        p={{ base: '0 20px 20px', lg: '40px' }}
        rounded='30px'
        bgImage='/images/home/home-partners-bg.webp'
        bgRepeat='no-repeat'
        bgSize='cover'
        bgPos='top 10% left 0%'
      >
        <Text
          as='h2'
          textStyle={{ base: 'heading-small-xl', lg: 'heading-large-xl' }}
          color='text&icon.tx-contrast'
          py={{ base: '20px', lg: undefined }}
          textAlign={{ base: undefined, lg: 'center' }}
        >
          {t('HomePartners.sectionTitle')}
        </Text>

        <Box
          maxW={{ base: undefined, lg: '1214px' }}
          m={{ base: undefined, lg: 'auto auto 0' }}
        >
          <Text
            as='h3'
            textStyle={{ base: 'heading-small-2xl', lg: 'heading-large-3xl' }}
            color='text&icon.tx-contrast'
            textAlign={{ base: undefined, lg: 'center' }}
          >
            {t('HomePartners.descriptionTitle')}
          </Text>

          <Text
            mt={{ base: '15px', lg: '37px' }}
            textStyle={{ base: 'text-regular-sm', lg: 'text-regular-xl' }}
            textAlign={{ base: undefined, lg: 'center' }}
          >
            {t('HomePartners.description')}
          </Text>
        </Box>

        <Button
          as='a'
          href='mailto:support@demuzo.com?subject=Request for partnership'
          variant='solid'
          colorScheme='white'
          size='md'
          w={{ base: 'full', lg: '250px' }}
          m={{ base: '0 auto', lg: '37px auto auto' }}
        >
          <Text textStyle='heading-large-sm'>
            {t('HomePartners.actionButton')}
          </Text>
        </Button>
      </Flex>
    </Box>
  );
};

export default HomePartners;
