import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { ElementType, ReactNode } from 'react';
import PanelHeader from '../../atoms/panel-header/panel-header';
import { HeaderType } from '../../atoms/panel-header/types';

type Props = {
  header?: string;
  rightAction?: ReactNode;
  headerType?: HeaderType;
  withPaddingHeader?: boolean;
  pxHeader?: string | number;
  as?: ElementType;
} & BoxProps;

export const SectionWhite = forwardRef<Props, 'div'>(
  (
    {
      children,
      header,
      rightAction,
      withPaddingHeader,
      headerType = 'h2',
      pxHeader,
      as,
      ...props
    },
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        {...props}
        bg={'background.bg-contrast'}
        rounded='30px'
        overflow='hidden'
      >
        {header ? (
          <PanelHeader
            as={as}
            variant='white'
            headerType={headerType}
            rightElement={rightAction}
            withPadding={withPaddingHeader}
            pHeader={{ md: '20px 0', lg: 0 }}
          >
            {header}
          </PanelHeader>
        ) : null}

        {children}
      </Box>
    );
  },
);

export default SectionWhite;
