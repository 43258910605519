import { AccordionData } from '@/shared/ui-kit/atoms/accordion/types';
import { PanelHeader } from '@/shared/ui-kit/atoms/panel-header';
import { SectionWhite } from '@/shared/ui-kit/molecules/section-white';
import { Link } from '@chakra-ui/next-js';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import Accordion from '@ui-kit/atoms/accordion/accordion';
import Divider from '@ui-kit/molecules/divider/divider';
import { useTranslations } from 'next-intl';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import { accordionsData } from '@/shared/constants/faq-accordion';

export const FaqBlock = () => {
  const t = useTranslations();
  const isMobile = useIsMobile();
  return (
    <SectionWhite mt='20px'>
      <Box p={{ base: '20px', lg: '20px 40px' }}>
        <PanelHeader
          headerType={isMobile ? 'h2' : 'h1'}
          variant='white'
          rightElement={
            <Link
              href='/faq'
              color='black&white.black'
              h={{ base: undefined, lg: '58px' }}
              border={{ base: undefined, lg: '1px solid' }}
              borderTop={{ base: undefined, lg: '1px solid' }}
              borderColor={{ base: undefined, lg: 'stroke.st-secondary' }}
              px={{ base: undefined, lg: '20px' }}
              rounded={{ base: undefined, lg: '30px' }}
              display={{ base: undefined, lg: 'flex' }}
              alignItems={{ base: undefined, lg: 'center' }}
              justifyContent={{ base: undefined, lg: 'center' }}
            >
              <Text
                textStyle={{ base: 'heading-large-sm', lg: 'heading-large-sm' }}
              >
                {t('FaqBlock.showAll')}
              </Text>
            </Link>
          }
        >
          {t('FaqBlock.title')}
        </PanelHeader>
      </Box>

      <Box
        p={{ base: '0 20px 20px', lg: '40px' }}
        pt={{ base: 0, lg: '100px' }}
      >
        <Flex
          gap={{ base: 0, lg: '20px' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          {accordionsData.map((item, index) => (
            <Box
              flex={1}
              key={index}
            >
              <Divider />
              <Accordion
                variant={isMobile ? 'black' : 'white'}
                data={item.values}
              />
            </Box>
          ))}
        </Flex>

        <Flex
          display={{ base: 'none', lg: 'flex' }}
          mt='132px'
          justify='flex-end'
          align='center'
          gap='30px'
        >
          <Text
            textStyle='text-regular-lg'
            color='text&icon.tx-secondary'
          >
            {t('FaqBlock.actionTitle')}
          </Text>

          <Button
            as='a'
            size='lg'
            variant='solid'
            colorScheme='black'
            href='mailto:support@demuzo.com'
          >
            <Text textStyle='heading-large-sm'>
              {t('FaqBlock.actionButton')}
            </Text>
          </Button>
        </Flex>
      </Box>
    </SectionWhite>
  );
};
